import React from 'react';
// import {Row, Form, Col} from 'reactstrap';

import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import FormField from './FormField/FormField';
import RadioSelect from './RadioSelect/RadioSelect';
import './FormFields.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  contact: {
    paddingBottom: '4rem',
    // marginTop: '6rem',
  },
  header: {
    color: theme.workwaveBlue,
    fontWeight: 600,
    textAlign: 'center',
  },
  subText: {
    color: theme.workwaveBlue,
    fontSize: '20px',
    textAlign: 'center',
  },
  formContainer: {
    background: theme.white,
    border: `1px solid ${theme.lightGray}`,
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    borderRadius: '20px',
    marginTop: '2rem',
    padding: '64px',
    paddingBottom: '84px',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: 'auto',
      margin: '0 16px',
    },
  },
  form: {
    // maxHeight: '465px',
    // overflow: 'hidden',
    // [theme.breakpoints.down('lg')]: {
    // 	maxHeight: '465px',
    // },
    // [theme.breakpoints.down('md')]: {
    // 	maxHeight: '820px',
    // },
  },
  supportFormRow: {
    '&:nth-child(even)': {
      paddingLeft: '8px',
      paddingRight: '0px !important',

      [theme.breakpoints.down(767)]: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      },
    },
    '&:nth-child(odd)': {
      paddingRight: '8px',
      paddingLeft: '0px !important',

      [theme.breakpoints.down(767)]: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      },
    },
  },
  supportFormRowFull: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
}));

const FormFields = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.formContainer}>
        <Typography variant='h3' color='primary' style={{ textAlign: 'center' }}>
          Calculate Your Business's Cost Savings
        </Typography>
        <Grid container style={{ margin: '2rem 0' }}>
          <RadioSelect />
        </Grid>
        <Grid container spacing={4}>
          {/* <form> */}
          <FormField label='Number of Field Workers' formId='techCount' step='1' min={1} />
          <FormField label='Hours Spent Planning Routes Per Day' append='Hours' step='.25' min={0.5} formId='hoursSpent' />
          <FormField label='Average Daily Distance Driven Per Field Worker (mi.)' append='Miles' min={1} step='1' formId='dailyDistance' />
          <FormField label='Average Hourly Wage' append='Dollars' step='.01' formId='avgWage' />
          <FormField label='Average Daily Drive-Time Per Field Worker (hrs.)' append='Hours' step='.25' formId='avgDriveTime' />
          <FormField label='Average Fuel Price Per Gallon' append='Dollars' step='.01' formId='avgFuelPrice' />
          {/* </form> */}
        </Grid>
      </Box>
    </>
  );
};

export default FormFields;
